.page {
    min-width: 99vw;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    &-nav {
        @media only screen and (max-width: $mobile) {
            display: none;
        }

        height: 100vh;
        max-height: 100vh;
        background-color: $navy;
        width: 7rem;
        padding: 1rem 0;
        display: flex;
        position: fixed;
        left: 0;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-logo {
            width: 60%;
            height: auto;

            @media only screen and (max-width: $mobile) {
                height: 3rem;
                width: 3rem;
                margin-right: .5rem;
            }
        }

        &-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &__link {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
                position: relative;
                border-right: 5px solid transparent;
                text-decoration: none;
                color: #bfbfbf;
                width: 100%;
                padding: 0.3rem 0.3rem;
                cursor: pointer;
                text-align: center;
                font-size: 12px;

                &--count {
                    background-color: $navy;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: .2rem;
                    top: .2rem;
                    justify-content: center;
                    height: 1.5rem;
                    width: 1.5rem;
                    border-radius: 100%;

                    p {
                        color: white;
                        font-weight: bold;
                        margin: 0 !important;
                    }

                    &--active {
                        background-color: white;

                        p {
                            color: $navy !important;

                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                svg {
                    margin-bottom: .5rem;
                }

                &:hover {
                    border-right: 5px solid $blue;
                    background-color: $navy;

                }

                &--active {
                    background-color: $blue;
                    border-right: 5px solid $blue;
                    color: white;
                    svg {
                        color: white !important;
                    }

                    &:hover {
                        background-color: $blue;
                    border-right: 5px solid $blue;
                    color: white;
                    }
                }
            }
        }
    }

    &-right {
        padding-left: 7rem;
        padding-top: 6rem;
        width: 100%;

        @media only screen and (max-width: $mobile) {
            padding-left: 0;
        }

        &-content {
            width: 100%;
        }

        &-top {
            width: calc(100vw - 7rem);
            position: fixed;
            left: 7rem;
            top: 0;
            height: 6rem;
            background-color: $navy;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width: $mobile) {
                left: 0;
                width: 100vw;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &-user {
                @media only screen and (max-width: $mobile) {
                    display: none !important;
                }

                p {
                    margin: 0;
                    color: white;
                    margin-right: 1rem;
                    margin-left: .5rem;

                    @media only screen and (max-width: $mobile) {
                        margin-right: 0;
                    }
                }
            }

            &__title {
                color: white;
                font-weight: bold;
                font-family: "MyFontBold";
                margin: 0;
            }
        }
    }
}