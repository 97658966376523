.status {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: 0;
    padding-top: .2rem;
    text-align: center;
    padding-bottom: .2rem;
    justify-content: center;
    color: white;
    &--red {
        background-color: rgb(225, 39, 39) !important;
    }
    &--orange {
        background-color: #f78d2a;
    }
    &--green {
        background-color: #2faa2f;
    }
    &--blue {
        background-color: $blue;
    }
    &__fullWidth {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}