.containerES {
    display: flex;
    flex-direction: row;
    width: 100%;

    &-children {
        width: 50%;
        height: calc(100vh - 6rem);

        &--reports {
            height: calc(100vh - 10rem) !important;
        }

        @media only screen and (max-width: $mobile) {
            width: 0%;
        }
    }

    &-ticket {
        position: relative;
        width: 50%;
        background: rgb(235, 235, 235);

        @media only screen and (max-width: $mobile) {
            width: 100%;
        }

        &__wave {
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 998;
        }

        &-content {
            padding: 2rem 3rem;
            z-index: 999;
            box-sizing: border-box;
            max-height: calc(100vh - 6rem);
            position: relative;
            overflow-y: auto;

            @media only screen and (max-width: $mobile) {
                padding: 1rem;
            }

            &-thumb {
                width: 12rem;
                margin-bottom: 1rem;
                cursor: pointer;
            }

            &__id {
                font-size: 24px;
                margin: 0;
            }

            &__close {
                cursor: pointer;
            }

            &__title {
                font-size: 32px;
                font-weight: 600;
            }

            &-description {
                font-size: 18px;
                margin-bottom: 1rem;
                white-space: pre-wrap;
            }

            &__label {
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                padding-right: .5rem;
            }

            &-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 1rem;

                @media only screen and (max-width: $mobile) {
                    flex-direction: column !important;
                    margin-bottom: 0;
                }
            }

            &__value {
                font-size: 18px;
            }

            &-comment {
                display: flex;

                &--blue {
                    justify-content: flex-end;
                }

                &-bubble {
                    background-color: white;
                    color: black;
                    padding: .5rem 1rem;
                    box-sizing: border-box;
                    margin-bottom: 1rem;
                    border-radius: 20px 20px 20px 0px;
                    width: 80%;

                    p {
                        margin: 0;
                    }

                    &__name {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    &__comment {
                        font-size: 14px;
                    }

                    &__date {
                        font-size: 14px;
                        text-align: right;
                    }

                    &--blue {
                        background-color: $blue;
                        color: white;
                        border-radius: 20px 20px 0px 20px;
                    }
                }
            }
        }
    }
}