@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

$main: white;
$navy: #130922;

$red: #e52521;
$redLight: #ff472b;
$blue: #74b3c0;
$blueDark: #58afc0;

$tablet: 1025px;
$mobile: 480px;

body {
  font-family: 'myFont', sans-serif !important;
  font-weight: normal;
  background-color: white !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-row {
  cursor: pointer;
  border: none;
  color: $navy;

  &:nth-of-type(even) {
    background-color: #f1f1f1;
  }

  &:nth-of-type(odd) {
    background-color: white;
  }
}

.MuiDataGrid-row.Mui-selected {
  background-color: gray !important;
}

.MuiDataGrid-columnHeaders {
  background-color: $navy !important;
  color: white !important;
}

.MuiDataGrid-footerContainer {
  background-color: $main !important;
}

.w-90 {
  width: 90%;
}

.MuiDataGrid-iconSeparator {
  color: white !important;
}

.rbt-token-removeable {
  display: flex !important;
}

.rbt-close-content {
  display: none !important;
}

.flow-min-height {
  min-height: 500px;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.editor-height {
  min-height: 20rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.toolbarClassName {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #ced4da !important;
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
  border-bottom: none !important;
  color: $navy !important;
}

.buttonES {
  background-color: $blue;
  color: white;
  border-color: $blue;
  outline: none;

  &:hover {
    background-color: $blueDark;
    background-color: $blueDark;
    outline: none;
  }

  &--navy {
    background-color: $navy;
    color: white;
    border-color: $navy;
    outline: none;

    &:hover {
      background-color: $navy;
      background-color: $navy;
      outline: none;
    }
  }
}

.css-ptkaw2-MuiDataGrid-root {
  border-radius: 0 !important;
  border: none !important;
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor{
  border-radius: 0 !important;
  border: none !important;
}

.css-aop0yh {
  border-radius: 0 !important;
  border: none !important;
}

.css-aop0yh {
  border-radius: 0 !important;
  border: none !important;
}

.css-y599qu {
  border-radius: 0 !important;
}

.mr-2 {
  margin-right: 1rem;
}

.w-0 {
  width: 0% !important;
}

.form-control-large {
  width: 60rem;
  height: 5rem;
  font-size: 3rem;

  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    height: auto;
    width: 90vw;
  }
}

.form-control-med {
  width: 60rem;
  font-size: 1.5rem;

  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    height: auto;
    width: 90vw;
  }
}

.form-control-half {
  width: 30rem;
  font-size: 1.5rem;

  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    height: auto;
    width: 43vw;
  }

  input[type=text] {
    width: 30rem;
    font-size: 1.5rem;

    @media only screen and (max-width: $mobile) {
      font-size: 20px;
      height: auto;
      width: 43vw;
    }
  }

  &--left {
    margin-left: .5rem;
  }

  &--right {
    margin-right: .5rem;

    input[type=text] {
      margin-right: .5rem;
    }
  }
}

.MuiTablePagination-selectLabel {
  margin: 0 !important;
  color: $navy !important;

}

.MuiTablePagination-displayedRows {
  margin: 0 !important;
  color: $navy !important;
}

.css-ptiqhd-MuiSvgIcon-root {
  color: white !important;
}

.css-12ituji-MuiSvgIcon-root-MuiSelect-icon {
  color: $navy !important;
}

.mr1 {
  margin-right: .5rem !important;
}

.assetReg {
  height: calc(100vh - 6rem);
}

.font-cursor {
  cursor: pointer;
}

.offcanvas {
  background-color: $main;
  color: white;
  width: 100vw !important;
}

.timeSpent {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.timeType {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.graph-container {
  height: calc(100vh - 7rem);
}

.guZdik {
  width: 100% !important;
  border-color: $blue !important;
  color: white !important;
  max-width: none !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 5rem !important;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.jWkLDY {
  flex-direction: column !important;
  align-items: center !important;
}

/* Hide entire page content */
@media print {
  body {
    display: none;
  }
}

/* Alternatively, hide specific sections of your page */
@media print {
  .containerES {
    display: none;
    /* Hide entire container */
  }

  /* Hide specific elements like buttons */
  .containerES-ticket__wave,
  .containerES-ticket-content__close,
  .containerES-ticket__actions {
    display: none;
  }

  /* Optionally blur sensitive content (like report data) */
  .data-sensitive {
    filter: blur(10px);
  }

  /* Example: hide only the DataGrid */
  .MuiDataGrid-root {
    display: none;
  }

  /* Custom message to display instead of content */
  .no-print-message::before {
    content: "This content is restricted from being printed.";
    font-size: 24px;
    color: red;
  }
}

/* Optionally, override some default styles for printing */
@media print {
  body {
    color: black;
    /* Ensure black text if there's a dark theme */
    background-color: white;
    /* Ensure white background for readability */
  }

  .containerES-ticket-content {
    font-size: 12px;
    /* Smaller font for printed version */
    padding: 10px;
    /* Adjust padding/margins for better print layout */
  }
}

.css-1j7qk7u {
  color: white !important;
}