.unAuth {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
  position: relative;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-color: $blue;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: $mobile) {
    flex-direction: column;
  }

  &-login {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      padding: 2rem;

      height: 50vh;
    }

    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__logo {
        width: 25rem;
        margin-bottom: 2rem;
      }

      &__header {
        margin-bottom: .5rem;
        font-weight: bold;
      }

      &__button {
        font-family: 'Segoe UI';
        font-weight: 600;
        color: #5E5E5E;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #8c8c8c;
        border-radius: 0;
        width: fit-content;
        padding: .5rem;
        transition: .3s;
        cursor: pointer;

        &:hover {
          background-color: $main;
          color: white;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &-blue {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    align-items: center;
    background-color: $navy;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      height: 50vh;
    }

    &__img {
      width: 100%;
      -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
    }
  }
}

.bg-blue {
  background-color: $navy;
}