.dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-menu {
        width: 100%;
        background-color: $blue;
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        a {
            cursor: pointer;
            font-weight: bold;
        }
    }
}