.raiseTicket {
    position: relative;
    width: calc(100vw - 7rem);
    max-width: calc(100vw - 7rem);
    z-index: 998;
    min-height: calc(100vh - 6rem);
    background: rgb(235, 235, 235);
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $mobile) {
        width: 100vw;
        max-width: 100vw;
        }
    &__control {
        color: $navy;
        text-decoration: none;
        font-size: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @media only screen and (max-width: $mobile) {
            font-size: 20px;
            }
    }
    &__label {
        font-size: 30px;
        font-weight: bold;
        
        margin: 0;
        @media only screen and (max-width: $mobile) {
            font-size: 14px;
            }
        &--small {
            font-size: 20px;
            margin: 0;
            font-weight: bold;
            @media only screen and (max-width: $mobile) {
                font-size: 14px;
                margin-top: 1rem;
                }
        }
    }
    &__wave {
        position: absolute;
        top: 0;
        width: calc(100vw - 7rem);
        max-width: calc(100vw - 7rem);
        max-height: calc(100vh - 6rem);
        z-index: 998;
        @media only screen and (max-width: $mobile) {
            width: 100vw;
        max-width: 100vw;
            }
    }
    &-one {
        z-index: 999;
    }
    &-two {
        z-index: 999 !important;
    }
    &-three {
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
            margin-bottom: 1rem;
        }
        p {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }
}