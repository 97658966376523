


.list-grid {
    min-height: calc(100vh - 6rem);
    display: grid;
    grid-template-columns: minmax(200px, .8fr) minmax(200px, .8fr) minmax(200px, .8fr) minmax(
        200px,
        .8fr
      );
    grid-gap: 8px;
    /* grid-auto-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr); */
  }
  
  .droppable {
    padding: 10px;
    background: rgb(235, 235, 235);

  }
  
  .drag-item {
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: white;
    margin: 0 0 8px 0;
    display: grid;
    color: black;
    grid-gap: 20px;
    flex-direction: column;
  }
  
  .prefix {
    border-bottom: 2px solid $navy;
    padding-bottom: .5rem;
    margin: 0;
    &__add {
      color: $navy !important;
      cursor: pointer;
      text-decoration: none;
      display: flex; 
      align-items: center;
      justify-content: center;
      svg {
        margin-right: .3rem;
      }
    }
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .modal-content {
    background-color: $main !important;
  }
  
  .dropContainer {
    padding: 1rem;
    margin-top: .5rem;
    min-height: 94%;
    background-color: white;
  }
  
  .projectCard {
    padding: 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: white;
      margin: 0 0 8px 0;
      color: black;
      border-left: $blue 10px solid;
      &--orange {
        border-left: orange 10px solid;
      }
      &--green {
        border-left: #2faa2f 10px solid;
      }
      &__id {
        padding: .3rem;
        background-color: $blue;
        width: fit-content;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin: 0;
        font-weight: bold;
        &--orange {
          background: orange;
        }
        &--green {
          background: #2faa2f;
        }
        &--red {
          background: #e12727;
        }
      }
      &__date {
        font-size: 12px;
      }
      &__title {
        font-weight: bold;
      }
      &__desc {
        font-size: 14px;
        white-space: pre-line;
      }
      &__name {
        margin: 0;
        padding: .3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        background-color: #dfdfdf;
        height: 30px;
        border-radius: 25px;
        width: 30px;
      }
  }
  
  .back-arrow {
    color: $navy;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: .5rem;
    svg {
        margin-right: .2rem;
    }
  }

  .custom-textbox {
    background-color: $main !important;
    border: none;
    
    &:focus {
      background-color: #212529 !important;
      border: 1px solid #86b7fe;
    }
  }